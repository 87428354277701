<template>
  <section class="mt-16"  v-if="items.length>0">
    <HeaderTitle
      title="Products"
      subtitle="showproduct"
    ></HeaderTitle>
    <v-container>
      <v-row class="pa-md-10 pa-5">
        <v-col cols="12" lg="4" v-for="(n, index) in items" :key="index" class="mb-md-10 mb-8" >
          
          <Carditems
            :image="n.image"
            :title="n.title"    
            @click="singlepro(n.id)"       
          ></Carditems>
        </v-col>
      </v-row>
    </v-container>
  </section>
  <section class="position-relative" style="height: 100vh;display: grid;
    place-items: center;"
    v-else
   >

<section class="cate">
</section>
      <v-progress-circular
      indeterminate
      color="green"
    ></v-progress-circular>

    </section>
</template>
<script>
import HeaderTitle from "@/components/HeaderTitle/HeaderTitle.vue";
import Carditems from "@/components/Carditems/Carditems.vue";
import CrudDataService from "@/Service/CrudDataService";
export default {
  components: {
    HeaderTitle,
    Carditems,
  },
  data() {
    return {
      items: [],
    };
  },
  methods: {
 
    singlepro(id) {
      this.$router.push({
        name: "singleProduct",
        params: { id: id },
      });
    },
    async allservices() {
      let res = await CrudDataService.getAll("services");
      this.items = res.data.data;
    },
  
  },
  mounted(){
    this.allservices()
  }
 
};
</script>
<style lang="scss"></style>
