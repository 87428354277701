<template>
    <section v-if="items.length>0">
        <section class="pt-md-10 pt-0">
    <v-container>
        <div v-for="(item, index) in items" :key="index" >
            <v-row v-if="item.key==='about_us_section_1'">
        <v-col
          cols="12"
          md="5"
          class="text-center"
        >
          <img :src="item.image" alt="" class="" width="90%"  v-if="item.key==='about_us_section_1'" />
        </v-col>
        <v-col cols="12" md="5" >
          <div class="sectionabout ma-lg-10 ma-0 mr-lg-0" v-if="item.key==='about_us_section_1'">
            <p class="me-1 pt-3" v-html="item.value?item.value[locallang]:''">
            </p>
          </div>
        </v-col>
      </v-row>
       <v-row class="">
        <v-col cols="12" md="6">
          <div class="sectionabout ma-lg-10 ma-0 mr-lg-0" v-if="item.key === 'about_us_section_2'">
           

            <p class="me-1 pt-3" v-html="item.value?item.value[locallang]:''">
            </p>
          </div>
        </v-col>
        <v-col cols="12" md="6">
          <img :src="item.image" alt="" class="" width="90%" v-if="item.key === 'about_us_section_2'"/>
        </v-col>
      </v-row> 
        </div>
   
    </v-container>
  </section>
  <Vision class="pb-16"/>
    </section>
    <section class="position-relative" style="height: 100vh;display: grid;
    place-items: center;"
    v-else
   >

<section class="cate">
</section>
      <v-progress-circular
      indeterminate
      color="green"
    ></v-progress-circular>

    </section>

</template>
<script>
import { onMounted, onUnmounted, ref } from "vue";
import Vision from '../vision/Vision.vue';
import aboutimage from "../../assets/image/Groupppp.jpg";
// import Groupmob from "../../assets/image/Groupmob.png"
import Groupsec from "../../assets/image/Groupsec.jpg";
import CrudDataService from "@/Service/CrudDataService";
export default {
    components:{
        Vision,
    },
  data() {
    return {
      aboutimage,
      Groupsec,
      items: [],
      sectionfirst:[]
    };
  },
  setup() {
    // Reactive property for storing the current value
    const locallang = ref(localStorage.getItem("lang") || "ar");
    // Function to poll localStorage for changes
    const pollLocalStorage = () => {
      const currentValue = localStorage.getItem("lang");
      if (locallang.value !== currentValue) {
        locallang.value = currentValue;
      }
    };

    // Polling interval
    let pollingInterval = null;

    onMounted(() => {
      // Start polling
      pollingInterval = setInterval(pollLocalStorage, 1000); // Poll every 1000ms (1 second)
    });

    onUnmounted(() => {
      // Clear interval when component is unmounted
      if (pollingInterval) {
        clearInterval(pollingInterval);
      }
    });

    return {
      locallang,
    };
  },
  methods: {
    async Aboutus() {
      let res = await CrudDataService.getAll("settings?page=about_us");
      this.items = res.data.data;
      console.log(this.items);
    },
  },
  mounted() {
    this.Aboutus();
  },
};
</script>
<style lang="scss" scoped>
.imggroupp {
  margin-top: -70px;
}

.sectionabout {
  h1 {
    font-size: 45px;
  }
  p {
    font-size: 18px;
  }
}
@media (max-width: 960px) {
  .imggroupp {
    margin-top: 0;
    width: 100% !important;
  }
  .sectionabout {
    width: 85%;
    margin: auto !important;
  }
}
</style>
