<template>
    <contact/>
</template>
<script>
import contact from "../components/Contact/Contact.vue"
export default {
    components:{
        contact
    }
}
</script>