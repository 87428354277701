<template>
  <section class="position-relative">
    <img
      :src="path"
      class="position-absolute imging"
      style="width: 63vw; top: -60px"
    />
    <v-container>
      <Content />
    </v-container>
  </section>
</template>
<script>
import path from "../../assets/image/Path.png";
import Content from "./Content.vue";
export default {
  components: {
    Content,
  },
  data() {
    return {
      path: path,
    };
  },
};
</script>
<style lang="scss">
[dir="ltr"] .imging{
  transform: rotateY(180deg);
}
@media (max-width: 960px) {
  .imging {
    display: none;
  }
}
</style>
