<template>
  <section class="blog pb-md-16 pb-5">
    <HeaderTitle title="blog" subtitle="followw"></HeaderTitle>
    <v-container>
      <v-window
        v-model="window"
        class="elevation-0 position-relative pa-12 px-sm-12 px-3"
        :show-arrows="false"
      >
        <v-window-item v-for="(group, i) in groupeditems" :key="i">
          <v-row>
            <v-col cols="12" lg="12" v-for="(n, index) in group" :key="index">
              <div>
                <img :src="n.image" height="500" width="85%" class="img_blog" />
                <h2 @click="singleblog(n.id)" style="cursor: pointer;">
                  {{ n.title[locallang] }}
                </h2>
                <div v-html="n.content?n.content[locallang]:''" class="paragraphblog">
                </div>
              </div>
            </v-col>
          </v-row>
        </v-window-item>
        <div class="position-absolute w-100" style="top: 50%">
          <div class="d-flex justify-space-between btns">
            <v-icon @click="next" class="iconright" size="40">
              mdi-navigation
            </v-icon>
            <v-icon @click="prev" class="iconleft" size="40"
              >mdi-navigation</v-icon
            >
          </div>
        </div>
      </v-window>
    </v-container>
  </section>
</template>
<script>
import { onMounted, onUnmounted, ref } from "vue";
import HeaderTitle from "../HeaderTitle/HeaderTitle.vue";
import CrudDataService from "@/Service/CrudDataService";
export default {
  components: {
    HeaderTitle,
  },
  data() {
    return {
      window: 0,
      items: [],
    };
  },
  setup() {
    // Reactive property for storing the current value
    const locallang = ref(localStorage.getItem("lang") || 'ar');

    // Function to poll localStorage for changes
    const pollLocalStorage = () => {
      const currentValue = localStorage.getItem('lang');
      if (locallang.value !== currentValue) {
        locallang.value = currentValue;
      }
    };

    // Polling interval
    let pollingInterval = null;

    onMounted(() => {
      // Start polling
      pollingInterval = setInterval(pollLocalStorage, 1000); // Poll every 1000ms (1 second)
    });

    onUnmounted(() => {
      // Clear interval when component is unmounted
      if (pollingInterval) {
        clearInterval(pollingInterval);
      }
    });

    return {
      locallang,
    };
  },
  computed: {
    // Group the products into sub-arrays of 3
    groupeditems() {
      const groups = [];
      for (let i = 0; i < this.items.length; i += 1) {
        groups.push(this.items.slice(i, i + 1));
      }
      return groups;
    },
  },
  methods: {
    next() {
      this.window++;
      if (this.window >= this.groupeditems.length) {
        this.window = 0; // Wrap around to the first window
      }
    },
    prev() {
      this.window--;
      if (this.window < 0) {
        this.window = this.groupeditems.length - 1; // Wrap around to the last window
      }
    },
    async allBlog() {
      let res = await CrudDataService.getAll("articles");
      this.items = res.data.data;
      console.log(this.items);
    },
    singleblog(id) {
      this.$router.push({
        name: "singleBlog",
        params: { id: id },
      });
    },
  },
  mounted(){
    this.allBlog()
  }
 
};
</script>
<style lang="scss" scoped>
.blog {
  text-align: center;
  .img_blog {
    border-radius: 8px;
  }
  p {
    font-size: 18px;
    padding-top: 10px;
    line-height: 32px;
    width: 75%;
    margin: auto;
  }
}
.arrow_card .v-btn--icon.v-btn--density-default {
  width: calc(var(--v-btn-height) + 1px);
  height: calc(var(--v-btn-height) + 1px);
}

.arrow_card .v-btn--icon.v-btn--size-default {
  --v-btn-size: 0.77rem;
  color: rgba(153, 153, 153, 0.5);
  border: 1px solid rgba(153, 153, 153, 0.5);
  box-shadow: none;
  margin: 0 5px;
}
.arrow_card .v-btn--icon:hover {
  color: white;
  background: rgba(107, 165, 64, 1);
  border: 1px solid rgba(107, 165, 64, 1);
}
.btns .iconright.mdi {
  transform: rotate(88deg);
  color: #6ba540;
}
.btns .iconleft.mdi {
  transform: rotate(-88deg);
  color: #6ba540;
}
</style>
