<template>
  <section class="blog pb-md-16 pb-5" v-if="blog">
    <HeaderTitle title="blog" subtitle="followw"></HeaderTitle>
    <v-container>
      <v-row >
        <v-col cols="12">
          <div class="mb-8">
            <img :src="blog.image" height="400" width="100%" class="img_blog" v-if="blog.image"/>
            <h1>
              {{ blog.title?blog.title[locallang]:'' }}

            </h1>
            <p class="text-start" v-html="blog.content?blog.content[locallang]:''">
            </p>
            
          </div>
        </v-col>
      </v-row>
    </v-container>
  </section>
  <section class="position-relative" style="height: 100vh;display: grid;
    place-items: center;"
    v-else
   >

<section class="cate">
</section>
      <v-progress-circular
      indeterminate
      color="green"
    ></v-progress-circular>

    </section>
</template>
<script>
import { onMounted, onUnmounted, ref } from "vue";
import CrudDataService from "@/Service/CrudDataService";
import HeaderTitle from "../HeaderTitle/HeaderTitle.vue";
export default {
  components: {
    HeaderTitle,
  },
  data() {
    return {
    blog:null
    };
  },
  setup() {
    // Reactive property for storing the current value
    const locallang = ref(localStorage.getItem("lang") || 'ar');

    // Function to poll localStorage for changes
    const pollLocalStorage = () => {
      const currentValue = localStorage.getItem('lang');
      if (locallang.value !== currentValue) {
        locallang.value = currentValue;
      }
    };

    // Polling interval
    let pollingInterval = null;

    onMounted(() => {
      // Start polling
      pollingInterval = setInterval(pollLocalStorage, 1000); // Poll every 1000ms (1 second)
    });

    onUnmounted(() => {
      // Clear interval when component is unmounted
      if (pollingInterval) {
        clearInterval(pollingInterval);
      }
    });

    return {
      locallang,
    };
  },
  methods: {
    async singleblog() {
      let res = await CrudDataService.get(
        "articles",
        `${this.$route.params.id}`
      );
      this.blog = res.data.data;
      
    },
  
  },
  mounted() {
    this.singleblog();
  },
};
</script>
<style lang="scss" scoped>
.blog {
  text-align: center;
  .img_blog {
    border-radius: 8px;
  }
  p {
    font-size: 18px;
    padding-top: 10px;
    line-height: 32px;
    width: 85%;
    margin: auto;
  }
}
</style>
