<template>
  <section>
    <HeaderTitle
      title="Products"
      subtitle="showproduct"
    ></HeaderTitle>
    <section class="position-relative main">
      <img :src="patii" alt="" class="w-100" style="margin-top: -22%" />

      <div class="sectionpro">
        <div>
          <h1>{{ $t('WhiteSaudi') }}</h1>

          <p class="me-1 pt-3 w-75">
            {{ $t('Usingmodern') }}
          </p>
        </div>
        <img :src="pngwingtr" class="mainimage rounded-circle" />
          <!-- <img :src="pngwingtr" alt="" class="ma-md-auto ma-0" width="80%" /> -->
        <!-- `<div class="replace text-center">
          <img :src="rep" alt="" class="ma-auto" width="60%"/>
        </div>` -->
      </div>

      <div class="sectionpro sec">
        <img :src="rrmmm" class="mainimage rounded-circle" />

        <!-- <v-img :src="rrmmm" class="mainimage">
          <img :src="rrmmm" alt="" class="ma-md-auto ma-0" width="80%" />
        </v-img> -->
        <!-- <div class="replace text-center">
          <img :src="repl" alt="" class="ma-auto" width="60%"/>
        </div> -->
        <div>
          <h1 class="w-75 mr-auto">
            {{ $t('BlueSaudi') }} </h1>

          <p class="me-1 pt-3 w-75 mr-auto">
            {{ $t('Usingmodern') }}  </p>
        </div>
      </div>
    </section>
    <section class="position-relative main">
      <img :src="patii" alt="" class="w-100" style="margin-top: -22%" />

      <div class="sectionpro">
        <div>
          <h1>{{ $t('eightslots') }}</h1>

          <p class="me-1 pt-3 w-75">
            {{ $t('Usingmodern') }}
          </p>
        </div>
        <img :src="eight" class="mainimage rounded-circle" />
      </div>

      <div class="sectionpro sec">
        <img :src="three" class="mainimage rounded-circle" />
        <div>
          <h1 class="w-75 mr-auto">
            {{ $t('Three') }} </h1>

          <p class="me-1 pt-3 w-75 mr-auto">
            {{ $t('Usingmodern') }}  </p>
        </div>
      </div>
    </section>
    <section class="position-relative main">
      <img :src="patii" alt="" class="w-100" style="margin-top: -22%" />

      <div class="sectionpro">
        <div>
          <h1>{{ $t('threeslots') }}</h1>

          <p class="me-1 pt-3 w-75">
            {{ $t('Usingmodern') }}
          </p>
        </div>
        <img :src="faticpng" class="mainimage rounded-circle" />
      </div>

      <div class="sectionpro sec">
        <img :src="fati" class="mainimage rounded-circle" />
        <div>
          <h1 class="w-75 mr-auto">
            {{ $t('insulator') }} </h1>

          <p class="me-1 pt-3 w-75 mr-auto">
            {{ $t('Usingmodern') }}  </p>
        </div>
      </div>
    </section>
    <section class="position-relative main" style="top:50%;">
      <img :src="patii" alt="" class="w-100" style="margin-top: -22%" />

      <div class="sectionpro">
        <div>
          <h1>{{ $t('swill') }}</h1>

          <p class="me-1 pt-3 w-75">
            {{ $t('Greatspongy') }}
          </p>
        </div>
        <img :src="sev2" class="mainimage rounded-circle" />
      </div>

 
    </section>
  </section>
</template>
<script>
import HeaderTitle from "@/components/HeaderTitle/HeaderTitle.vue";
import patii from "@/assets/image/Patii.png";
import Groupimg from "@/assets/image/Groupimg.png";
import pngwingtr from "@/assets/image/pngwingtr.png";
import rrmmm from "@/assets/image/rrrmm.png";
import eight from "@/assets/image/eight.png"
import three from "@/assets/image/three.png"
import faticpng from "@/assets/image/faticpng.png"
import fati from "@/assets/image/fati.png"
import sev2 from "@/assets/image/serv22.png"

export default {
  components: {
    HeaderTitle,
  },
  data() {
    return {
      sev2,
      patii,
      Groupimg,
      pngwingtr,
      rrmmm,
      eight,
      three,
      faticpng,
      fati
    };
  },
};
</script>
<style lang="scss">
.mainimage .v-responsive__content {
  display: flex !important;
}
</style>
<style lang="scss" scoped>
 [dir="ltr"] .main >img{
  transform: rotateY(180deg);
  
  }
// .replace{
//   display: none;
// }
.mainimage {
  box-shadow: 0px 25px 23px 0px rgba(0, 0, 0, 0.15);

  width: 280px;
  height: 280px;
}
.sectionpro {
  margin-bottom: 70px;
  display: flex;
  position: absolute;
  width: 70%;
  right: 20%;
  top: 10%;
  justify-content: center;
  align-items: center;
  h1 {
    font-size: 36px;
  }
  p {
    font-size: 18px;
  }
}
.sectionpro.sec {
  top: 50%;
  bottom: 0%;
}
@media (max-width: 1199.98px) {
  .sectionpro {
    right: 5%;
    width: 90%;
  }
  
}
@media (max-width: 991.98px) {
  .mainimage {
    width: 200px;
    height: 200px;
  }
}
@media (max-width: 767.98px) {
//   .mainimage {
// display: none;  }

  .sectionpro {
    top: 0;
    flex-direction: column;
    p {
      width: 95% !important;
    }
    h1{
      font-size: 18px !important;
    }
  }
  .sectionpro.sec {
    top: 50%;
    h1{
      margin-top: 15px;
        margin-right: 0 !important;
    }
    p{
        margin-right: 0 !important;
    }
  }

  .main >img{
   margin-top: 0 !important;
   transform: scale(1.8);
  }
  .main {
    height: 125vh;
}

}
@media (max-width: 575.98px) {
    .sectionpro.sec {
    top: 0%;}
 
}
</style>
