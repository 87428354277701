<template>
  <section>
    <v-container>
      <v-card class="pa-md-5 pa-1 mx-md-16 mx-2">
        <h3 class="text-center titlecontact">
          {{ $t("Contact") }}
          <hr
            width="120"
            style="
              height: 2px;
              border: none;
              border-radius: 50px;
              background-color: #6ba540;
            "
            class="text-center ma-auto mt-2"
          />
        </h3>
        <v-row class="pa-md-5 pa-2 ma-md-5 ma-0">
          <v-col cols="12" md="6">
            <v-text-field
              :label="$t('name')"
              variant="outlined"
              v-model="formData.name"
            ></v-text-field>
            <v-text-field
              :label="$t('email')"
              variant="outlined"
              v-model="formData.email"
              type="email"
            ></v-text-field>
            <v-text-field
              :label="$t('phone_number')"
              variant="outlined"
              v-model="formData.phone"
              type="tel"
            ></v-text-field>
            <v-textarea
              :label="$t('Write_message')"
              auto-grow
              variant="outlined"
              rows="3"
              row-height="25"
              shaped
              v-model="formData.message"
            ></v-textarea>
            <div class="text-red my-2">
              {{ errormessage ? errormessage : "" }}
            </div>
            <v-btn class="w-100 btnaction text-white" @click="addcontact">
              {{ $t("send") }}
            </v-btn>
          </v-col>

          <v-col cols="12" md="6" class="overflow-hidden">
            <div
              class="text-end font-weight-medium mt-md-12 pt-md-12 pt-2 mt-2"
            >
              <p class="py-2">0546010105 - 0537454644</p>
              <p class="py-2">taibapower@gmail.com</p>
              <div class="d-flex justify-end">
                <div class="py-2" v-for="(item, index) in items" :key="index">
                  <a
                    :href="item.value.en"
                    class="text-black"
                    target="_blank"
                    v-if="item.key === 'twitter_link'"
                  >
                    <v-icon icon="mdi mdi-twitter" class="mx-1"></v-icon>
                  </a>
                  <a
                    :href="item.value.en"
                    class="text-black"
                    target="_blank"
                    v-if="item.key === 'facebook_link'"
                  >
                    <v-icon icon="mdi mdi-facebook" class="mx-1"></v-icon>
                  </a>
                  <a
                    :href="item.value.en"
                    class="text-black"
                    target="_blank"
                    v-if="item.key === 'instagram_link'"
                  >
                    <v-icon icon="mdi mdi-instagram" class="mx-1"></v-icon>
                  </a>
                </div>
              </div>
            </div>
            <a
              href="https://www.google.com/maps/place/24%C2%B007'23.4%22N+39%C2%B059'46.9%22E/@24.1029301,39.943084,8.3z/data=!4m4!3m3!8m2!3d24.1231629!4d39.9963558?hl=ar&entry=ttu"
              target="_blank"
              rel="noopener noreferrer"
            >
              <l-map
                style="height: 300px"
                :zoom="zoom"
                :options="mapOptions"
                :center="center"
              >
                <l-tile-layer :url="url"></l-tile-layer>
                <l-marker :lat-lng="markerLatLng"></l-marker>
              </l-map>
            </a>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <div
      class="d-md-flex d-none justify-space-between align-center py-md-16 lastimg"
    >
      <img :src="pathin" width="150" />
      <img :src="pathcon" width="150" />
    </div>
    <v-snackbar v-model="showSnackbar"  color="green">
{{ $t("willcontact") }}
    <template v-slot:action="{ attrs }">
      <v-btn color="pink" v-bind="attrs" @click="showSnackbar = false">
        Close
      </v-btn>
    </template>
  </v-snackbar>
  </section>
</template>
<script>
import { LMap, LTileLayer, LMarker } from "vue3-leaflet";
import "leaflet/dist/leaflet.css";
import pathin from "../../assets/image/pathcontact.png";
import pathcon from "../../assets/image/pathcon.png";
import CrudDataService from "@/Service/CrudDataService";
export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
  },
  data() {
    return {
    showSnackbar: false,
      pathcon,
      pathin,
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      zoom: 7,
      center: [23.5052517, 39.6077229],
      markerLatLng: [24.1231629, 39.9963558],
      mapOptions: {
        zoomControl: true,
        scrollWheelZoom: false,
        touchZoom: false,
        doubleClickZoom: true,
        boxZoom: false,
      },
      errormessage: "",
      formData: {
        name: "",
        email: "",
        phone: "",
        message: "",
      },
      items: [],
    };
  },
  methods: {
    async addcontact() {
      const res = await CrudDataService.create("contacts", this.formData)
        .then((response) => {
          this.errormessage = "";
          this.showSnackbar = true;
          this.formData = { name: "", email: "", phone: "", message: "" };
        })
        .catch((errors) => {
          this.errormessage = errors.response.data.message;
        });
    },
    async allsocial() {
      let res = await CrudDataService.getAll("settings?page=social_links");
      this.items = res.data.data;
    },
  },
  mounted() {
    this.allsocial();
  },
};
</script>
<style lang="scss">
[dir="ltr"] .lastimg img {
  transform: rotateY(180deg);
}
.leaflet-container.leaflet-touch-drag {
  touch-action: none;
  touch-action: pinch-zoom;
  height: 200px !important;
}
.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
  min-height: 175px !important;
}
.btnaction {
  background: #6ba540;
}
.titlecontact {
  color: #6ba540;
}
</style>
