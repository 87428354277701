<template>
  <v-card class="mx-auto carditem" style="border-radius: 15px">
    <v-img
      class="align-end text-white"
      height="300"
      style="border-radius: 15px"
      :src="image"
    >
    </v-img>
    <div class="py-5 text-black">
      <h4>{{ title[locallang] }}</h4>
    </div>
  </v-card>
</template>

<script>
import { onMounted, onUnmounted, ref } from "vue";
export default {
  props: {
    image: {
      type: String,
    },
    title: {
      type: Object,
    },
    number: {
      type: Number,
    },
  },
  setup() {
    // Reactive property for storing the current value
    const locallang = ref(localStorage.getItem("lang") || 'ar');

    // Function to poll localStorage for changes
    const pollLocalStorage = () => {
      const currentValue = localStorage.getItem('lang');
      if (locallang.value !== currentValue) {
        locallang.value = currentValue;
      }
    };

    // Polling interval
    let pollingInterval = null;

    onMounted(() => {
      // Start polling
      pollingInterval = setInterval(pollLocalStorage, 1000); // Poll every 1000ms (1 second)
    });

    onUnmounted(() => {
      // Clear interval when component is unmounted
      if (pollingInterval) {
        clearInterval(pollingInterval);
      }
    });

    return {
      locallang,
    };
  },
};
</script>
<style scoped lang="scss">
.carditem {
  text-align: center;
  box-shadow: 0px 4px 4px 0px #6ba540;
}
</style>
