<template>
    <div>
        <Products/>
    </div>
</template>
<script>
import Products from '@/components/Products/Products.vue'
export default {
    components:{
        Products
    }
}
</script>
<style lang="">
    
</style>