<template >
   <section class="footergeneral">
    <v-container>
        <v-row>
            <v-col cols="12" sm="4">
              <div >
                <img :src="logo" alt="" @click="redirectto('/')" />
                <p class="w-75 text-white" >
                 {{ footerparagraph.value?footerparagraph.value[locallang]:'' }}
                </p>
                <div  class="d-flex ">
                  <div class="mt-4 " v-for="(item,index) in social" :key="index">
                    <a
                    :href="item.value.en"
                    class="text-black"
                target="_blank"
                v-if="item.key==='instagram_link'"
              >
              <img :src="insta" alt="" class="mx-2 social">

              </a>
              <a
              :href="item.value.en"
               class="text-black"
                target="_blank"
                v-if="item.key==='facebook_link'"
              >
                    <img :src="facebook" alt="" class="mx-2 social">
              </a>
              <a
              :href="item.value.en"
                class="text-black"
                target="_blank"
                v-if="item.key==='twitter_link'"
              >
              <img :src="twitter" alt="" class="mx-2 social">
              </a>
                </div>
             
            </div>
            
              </div>
             
            </v-col>
            <v-col cols="12" sm="1"></v-col>
            <v-col cols="12" sm="3" >
                <h3 class="text-white mt-sm-8 mt-0 mb-sm-5 mb-3">
                    {{ $t('Abouut') }}
                    
                </h3>
                <p class="mb-3 text-white routepage"  v-for="(item, index) in items"
                  :key="index"
                  style="cursor: pointer;"
                  @click="redirectto(item.route)">
                  {{ $t(item.text) }}
                  </p>
            </v-col>
            <v-col cols="12" sm="4" >
                <h3 class="text-white mt-sm-8 mt-0 mb-sm-5 mb-3">
                 {{ $t('Stayup') }}
                </h3>
                <p class="mb-3 text-white routepage"
                 >
                 {{ $t("Subscribe")}}
                  </p>
                  <div class="mb-5">
                    <a
                    href="https://maps.app.goo.gl/aLKXFzUvXNRAjoCY6?g_st=iw"
                class="text-white text-decoration-none py-2"
                target="_blank"
              >
              <v-icon icon="mdi mdi-map-marker-radius"></v-icon>
              {{ locationsaddress.value?locationsaddress.value[locallang]:'' }}
              </a>

                  </div>
                  <button>
                    <a
                    :href="`https://wa.me/${phonenum.value?phonenum.value[locallang]:phonenum.value}`"
                class="text-white text-decoration-none py-2 px-3"
                style="border: 1px solid #fff; border-radius: 25px;"
                target="_blank"
              >
             {{ $t('contact') }}
              </a>
                  </button>
            </v-col>

        </v-row>
        <div
      class="pt-4 mt-4 py-2 text-center w-100 text-white"
      style="border-top: 1px solid #4C4D8B;"
    >
      © 2024 code 7x
    </div>
    </v-container>
    
   </section>
</template>
<script>
import { onMounted, onUnmounted, ref } from "vue";
import image from "../../../src/assets/image/logo.png";
import insta from "../../../src/assets/image/insta.png";
import facebook from "../../../src/assets/image/facebook.png";
import twitter from "../../../src/assets/image/twitter.png";
import CrudDataService from "@/Service/CrudDataService";
export default {
    data(){
        return{
            logo: image,
            insta,
            facebook,
            twitter,
            items: [
      { text: "allblog", route: "/allblog" },

                { text: "Products", route: "/services" },
      { text: "About", route: "/about" },
      { text: "Contact", route: "/contact" },
    ],
    dataitems:[],
    footerparagraph:'',
    locationsaddress:'',
    phonenum:'',
    social:[]
        }
    },
    setup() {
    // Reactive property for storing the current value
    const locallang = ref(localStorage.getItem("lang") || 'ar');

    // Function to poll localStorage for changes
    const pollLocalStorage = () => {
      const currentValue = localStorage.getItem('lang');
      if (locallang.value !== currentValue) {
        locallang.value = currentValue;
      }
    };

    // Polling interval
    let pollingInterval = null;

    onMounted(() => {
      // Start polling
      pollingInterval = setInterval(pollLocalStorage, 1000); // Poll every 1000ms (1 second)
    });

    onUnmounted(() => {
      // Clear interval when component is unmounted
      if (pollingInterval) {
        clearInterval(pollingInterval);
      }
    });

    return {
      locallang,
    };
  },
    methods: {
      redirectto(path) {
      this.$router.push(path);
    },
    async alldata() {
      let res = await CrudDataService.getAll("settings?page=general");
      this.dataitems = res.data.data;
      this.dataitems.forEach(element => {
        if (element.key==='footer_paragraph') {
          this.footerparagraph=element
        }else if (element.key==='footer_address') {
          this.locationsaddress=element
        }else if (element.key==='footer_phone') {
          this.phonenum=element
          
        }
      });
    },
    async allsocial() {
      let res = await CrudDataService.getAll("settings?page=social_links");
      this.social = res.data.data;
    },
  },
  mounted(){
    this.alldata();
    this.allsocial()
  }
   
}
</script>
<style lang="scss">
    .footergeneral{
        background: linear-gradient(212deg, #87876F 19.24%, #6BA540 136.13%);
    .routepage{
        font-size: 20px;
    }
    }
    .social{
        width: 30px;
    }
</style>