<template>
  <v-app v-if="loading">
    <v-main>
      <div v-if="$route.path == '/'">
        <Navbar />
      </div>
      <div v-else>
        <Navbar class="item" />
      </div>
      <router-view/>
      <Footer/>
    </v-main>
  </v-app>
  <section class="position-relative" style="height: 100vh;display: grid;
    place-items: center;"
    v-else
   >

<section class="cate">
</section>
      <v-progress-circular
      indeterminate
      color="green"
    ></v-progress-circular>

    </section>
</template>

<script>
import Navbar from "./components/Navbar/Navbar.vue"
import Footer from "./components/Footer/Footer.vue"
import CrudDataService from "./Service/CrudDataService"
export default {
  name: 'App',
  components:{
    Navbar,
    Footer
  },
  data: () => ({
    loading:false,
    items:[]
  }),
  methods:{
    async main() {
      this.loading=false
      let res = await CrudDataService.getAll("settings?page=main_page").then((res)=>{
      this.items = res.data.data;
      this.loading=true
      })
    },
  },
  mounted() {
   this.main()
  },
}
</script>
<style lang="scss">

</style>