import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'
import ProductView from '../views/ProductView.vue'
import ServicesView from '../views/ServicesView.vue'
import ContactView from '../views/ContactView.vue'
import singleProduct from '../views/singleProduct.vue'
import AllBlog from '../components/Blogs/AllBlog.vue'
import Singleblog from '../components/Blogs/Singleblog.vue'
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/services',
    name: 'ServicesView',
    component: ServicesView
  },
  {
    path: '/about',
    name: 'AboutView',
    component: AboutView
  },
  {
    path: '/products',
    name: 'ProductView',
    component: ProductView
  },
  {
    path: '/contact',
    name: 'ContactView',
    component: ContactView
  },
  {
    path: '/allblog',
    name: 'AllBlog',
    component: AllBlog
  },
  {
    path: '/Singleblog/:id',
    name: 'singleBlog',
    component: Singleblog
  },
  {
    path:'/singleProduct/:id',
    name:'singleProduct',
    component:singleProduct
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // Always scroll to the top of the page on route change
    return { top: 0 };
  }
})

export default router
