<template>
     <section>
        <div class="">
    <v-img :src="stork" class="main-image">
      <!-- <img
        :src="sky"
        alt=""
        style="position: absolute; left: 7%; z-index: 0; width: 80%"
      /> -->

      <div
        
        class="d-flex align-center justify-content-center text-center itemmargin"

        style="height: 100% !important; "
      >       
        <div class="content ma-auto" >
          <div v-for="(item, index) in items" :key="index">
            <h1 class="text-md-h3 text-sm-h4 text-h3 font-weight-bold" style="color: #616160;font-family: 'Tajawal', sans-serif !important;"
            v-if="item.key==='main_page_title'">
            {{ item.value[locallang] }}  
            </h1>
            <p class="paragraph-text  py-md-5 py-2 ma-auto"  v-if="item.key==='main_page_description'">
            {{ item.value[locallang] }}
            </p>
          </div>
            <button class="more_than" @click="redirectto('/about')">{{ $t('know_more')}} </button>
          </div>
         
      </div>
    </v-img>
  </div>
</section>

</template>

<script>
import { onMounted, onUnmounted, ref } from "vue";
import sky from "../../assets/image/pngwing.png";
// import ghhhh from "../../assets/image/ghhhh.png";
import ghhhh from "../../assets/image/Volcano.png";
import headerimage from "../../assets/image/Rectangl.png";
import CrudDataService from "@/Service/CrudDataService";
export default {
  data() {
    return {
      stork: ghhhh,
      sky: sky,
      headerimage: headerimage,
      items:[]
    };
  },
  setup() {
    // Reactive property for storing the current value
    const locallang = ref(localStorage.getItem("lang") || 'ar');

    // Function to poll localStorage for changes
    const pollLocalStorage = () => {
      const currentValue = localStorage.getItem('lang');
      if (locallang.value !== currentValue) {
        locallang.value = currentValue;
      }
    };

    // Polling interval
    let pollingInterval = null;

    onMounted(() => {
      // Start polling
      pollingInterval = setInterval(pollLocalStorage, 1000); // Poll every 1000ms (1 second)
    });

    onUnmounted(() => {
      // Clear interval when component is unmounted
      if (pollingInterval) {
        clearInterval(pollingInterval);
      }
    });

    return {
      locallang,
    };
  },
  methods: {
    redirectto(path) {
      this.$router.push(path);
    },
    async main() {
      let res = await CrudDataService.getAll("settings?page=main_page");
      this.items = res.data.data;
    },
  },
  mounted(){
    this.main()
  }
  
};
</script>
<style lang="scss" >
.itemmargin{
  margin-top: -50px;
}
.collection-header {
  bottom: 13%;
}
.paragraph-text {
  font-size: 28px;
  color: #fff;

  width: 75% !important;
}
.more_than {
  
  color: #fff;
  background: #6BA540;
  padding: 10px 15px;
  border-radius: 25px;
  font-size: 18px;
}
@media (max-width: 1400px) {
 
 .content h1 {
    font-size: 2rem !important;
    
}
[dir="ltr"] .content .paragraph-text{
    padding-top: 0 !important;
  }
}
@media (max-width: 1300px) {
  .content .paragraph-text{
    font-size: 19px;
  }
  .collection-header {
    bottom: 8%;
  }
  [dir="ltr"] .content h1 {
    font-size: 1.7rem !important;
    
}
}
@media (max-width: 1199px) {
  .content .paragraph-text{
    font-size: 19px;
  }
  .collection-header {
    bottom: 8%;
  }
  .itemmargin{
  margin-top: 20px;
}
}
@media (max-width: 960px) {

  .content .paragraph-text{
    font-size: 22px;
    width: 95% !important;
  }
  .naav {
    width: 100%;
  }
  .stork {
    display: none;
  }
  .content {
    padding-right: 5px;
    padding-left: 5px;
  }

[dir="ltr"] .icon-menu {
    margin-right: 0 !important;
    margin-left: auto !important;
}
 
}
@media (max-width: 767px) {
.main-image .v-img__img--contain {
    object-fit: cover !important;
}
    .main-image {
    height: 120vh;
  }
  .itemmargin {
    margin-top: -120px;
}
}
@media (max-width: 575px) {
  .paragraph-text {
    font-size: 18px !important;

}}
@media (max-width: 400px) {

[dir="ltr"]   .paragraph-text {   
   font-size: 17px !important;

}
[dir=ltr] .content h1 {
    font-size: 1.5rem !important;
}
}
</style>
