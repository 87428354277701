<template>
  <section class="mt-16 " v-if="product">
    <HeaderTitle title="Products" subtitle="showproduct"></HeaderTitle>
    <v-container>
      <v-row class="pa-md-10 pa-5" justify="space-between">
        <v-col cols="12" md="5" sm="5">
            <v-img
      class="align-end "
      height="300"
      style="border-radius: 15px;  box-shadow: 0px 2px 3px 0px #6ba540;
"
      :src="product.image"
      
    >
    </v-img>
              </v-col>
              <v-col cols="12" md="6"  sm="7">
                <h2 class="">
                  {{ product.title?product.title[locallang]:'' }}
              </h2>      
              <p>
                {{ product.description?product.description[locallang]:'' }}
                </p>          
                 <ul style="list-style: none;" class="mt-5">
                    <li  v-for="(item,index) in product.features" :key="index">
                      <div class="d-flex align-center" v-if="item[locallang]!=null">
                        <img :src="li" alt="" width="25">
                        <p class="font-weight-bold px-2" >
                            {{ item?item[locallang]:'' }}
                        </p>
                      </div>
                      
                    </li>
                </ul> 
              </v-col>
      </v-row>
    </v-container>
  </section>
  <section class="position-relative" style="height: 100vh;display: grid;
    place-items: center;"
    v-else
   >

<section class="cate">
</section>
      <v-progress-circular
      indeterminate
      color="green"
    ></v-progress-circular>

    </section>
</template>
<script>
import { onMounted, onUnmounted, ref } from "vue";
import li from "../assets/image/tap.png"
import HeaderTitle from "@/components/HeaderTitle/HeaderTitle.vue";
import Carditems from "@/components/Carditems/Carditems.vue";
import CrudDataService from "@/Service/CrudDataService";
export default {
  components: {
    HeaderTitle,
    Carditems,
  },
  data() {
    return {
        li,
      product: null,
    };
  },
  setup() {
    // Reactive property for storing the current value
    const locallang = ref(localStorage.getItem("lang") || 'ar');

    // Function to poll localStorage for changes
    const pollLocalStorage = () => {
      const currentValue = localStorage.getItem('lang');
      if (locallang.value !== currentValue) {
        locallang.value = currentValue;
      }
    };

    // Polling interval
    let pollingInterval = null;

    onMounted(() => {
      // Start polling
      pollingInterval = setInterval(pollLocalStorage, 1000); // Poll every 1000ms (1 second)
    });

    onUnmounted(() => {
      // Clear interval when component is unmounted
      if (pollingInterval) {
        clearInterval(pollingInterval);
      }
    });

    return {
      locallang,
    };
  },
  methods: {
    async singleproduct() {
      let res = await CrudDataService.get(
        "services",
        `${this.$route.params.id}`
      );
      this.product = res.data.data;
      
    },
   
  },
  mounted() {
    this.singleproduct();
  },
};
</script>
<style lang="scss"></style>
