<template>
  <section class="blog pb-md-16 pb-5" v-if="items.length > 0">
    <HeaderTitle title="allblog" subtitle="followw"></HeaderTitle>
    <v-container>
      <v-row>
        <v-col cols="12" sm="6" v-for="(item, i) in items" :key="i">
          <div>
            <img :src="item.image" height="300" width="100%" class="img_blog" />
            <h2 @click="singleblog(item.id)" style="cursor: pointer">
              {{ item.title ? item.title[locallang] : "" }}
            </h2>
            <div
              v-html="item.content ? item.content[locallang] : ''"
              class="paragraphblog"
            ></div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </section>
  <section
    class="position-relative"
    style="height: 100vh; display: grid; place-items: center"
    v-else
  >
    <section class="cate"></section>
    <v-progress-circular indeterminate color="green"></v-progress-circular>
  </section>
</template>
<script>
import { onMounted, onUnmounted, ref } from "vue";
import HeaderTitle from "../HeaderTitle/HeaderTitle.vue";
import CrudDataService from "@/Service/CrudDataService";
export default {
  components: {
    HeaderTitle,
  },
  data() {
    return {
      items: [],
    };
  },
  setup() {
    // Reactive property for storing the current value
    const locallang = ref(localStorage.getItem("lang") || "ar");

    // Function to poll localStorage for changes
    const pollLocalStorage = () => {
      const currentValue = localStorage.getItem("lang");
      if (locallang.value !== currentValue) {
        locallang.value = currentValue;
      }
    };

    // Polling interval
    let pollingInterval = null;

    onMounted(() => {
      // Start polling
      pollingInterval = setInterval(pollLocalStorage, 1000); // Poll every 1000ms (1 second)
    });

    onUnmounted(() => {
      // Clear interval when component is unmounted
      if (pollingInterval) {
        clearInterval(pollingInterval);
      }
    });

    return {
      locallang,
    };
  },
  methods: {
    singleblog(id) {
      this.$router.push({
        name: "singleBlog",
        params: { id: id },
      });
    },
    async allBlog() {
      let res = await CrudDataService.getAll("articles");
      this.items = res.data.data;
    },
  },
  mounted() {
    this.allBlog();
  },
};
</script>
<style lang="scss" scoped>
.blog {
  text-align: center;
  .img_blog {
    border-radius: 8px;
  }
  p {
    font-size: 18px;
    padding-top: 10px;
    line-height: 32px;
    width: 75%;
    margin: auto;
  }
}
.arrow_card .v-btn--icon.v-btn--density-default {
  width: calc(var(--v-btn-height) + 1px);
  height: calc(var(--v-btn-height) + 1px);
}

.arrow_card .v-btn--icon.v-btn--size-default {
  --v-btn-size: 0.77rem;
  color: rgba(153, 153, 153, 0.5);
  border: 1px solid rgba(153, 153, 153, 0.5);
  box-shadow: none;
  margin: 0 5px;
}
.arrow_card .v-btn--icon:hover {
  color: white;
  background: rgba(107, 165, 64, 1);
  border: 1px solid rgba(107, 165, 64, 1);
}
.btns .iconright.mdi {
  transform: rotate(88deg);
  color: #6ba540;
}
.btns .iconleft.mdi {
  transform: rotate(-88deg);
  color: #6ba540;
}
</style>
