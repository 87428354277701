<template>
    <v-row>
        <v-col cols="12" md="6">
          <div class="vision_title mt-lg-16 mt-md-8 pt-lg-16 pt-md-8" style="top: 0;">
    <div class="pb-lg-4 pb-0" v-for="(item, index) in items" :key="index">
      <div v-if=" item.type=== 'textarea'">
        <h2 class="pb-lg-7 pb-md-4">{{ $t(item.key) }}</h2>
    <p>
  {{ item.value[locallang] }}
   </p>
    </div>
  </div>
  </div>
        </v-col>
        <v-col cols="12" md="6">
          <div class="imagesside mt-md-16 mt-10 pt-md-8 pt-5 text-center" style="top: 0">
              <img :src="item.image" class=""  v-for="(item, index) in items" :key="index" :style="item.type=== 'image'?'display:block':'display:none'"/>
          </div>
        </v-col>

      </v-row>
  
 
</template>
<script>
import CrudDataService from "@/Service/CrudDataService";
import { onMounted, onUnmounted, ref } from "vue";
export default {
  data() {
    return {
      window: 0,
      items: [],
      
    };
  },
  setup() {
    // Reactive property for storing the current value
    const locallang = ref(localStorage.getItem("lang") || 'ar');

    // Function to poll localStorage for changes
    const pollLocalStorage = () => {
      const currentValue = localStorage.getItem('lang');
      if (locallang.value !== currentValue) {
        locallang.value = currentValue;
      }
    };

    // Polling interval
    let pollingInterval = null;

    onMounted(() => {
      // Start polling
      pollingInterval = setInterval(pollLocalStorage, 1000); // Poll every 1000ms (1 second)
    });

    onUnmounted(() => {
      // Clear interval when component is unmounted
      if (pollingInterval) {
        clearInterval(pollingInterval);
      }
    });

    return {
      locallang,
    };
  },
  methods: {
    async allvisions() {
      let res = await CrudDataService.getAll("settings?page=visions_missions");
      this.items = res.data.data;
    },
  },
  mounted(){
    this.allvisions()
  }
};
</script>
<style lang="scss">
.vision_title{
    position: relative;
    z-index: 11111;
 h2{
font-size: 42px;
}
 p{
font-size: 18px;
line-height: 32px;

}
}
@media (max-width: 1399px) { 

 }
@media (max-width: 1199px) {

}
@media (max-width: 960px) {
.vision_title{
    width: 75%;
    margin: auto;
}
 
}
@media (max-width: 767px) {

}
@media (max-width: 575px) {
    .vision_title{
    width: 95%;
}
}
@keyframes example {
	0% {transform: scale(1)  }
	50% { transform: scale(1.1) }
  100% { transform: scale(1) }
}
.imagesside img{
    display: block;
border-radius:50% ;
border: 2px solid #fff;
animation: example 3s infinite;
box-shadow: 0px 25px 23px 0px rgba(0, 0, 0, 0.15);
}
.imagesside img:first-child{
    margin: auto;
width: 100px;
height: 100px;
}
.imagesside img:nth-child(2){
    margin-right: auto;
width: 200px;
height: 200px;
}
.imagesside img:nth-child(3){
    margin: auto;
width: 100px;
height: 100px;
}
.imagesside img:nth-child(4){
    margin-right: auto;
width: 200px;
height: 200px;
}
.imagesside img:nth-child(odd) {
    margin: auto;
width: 100px;
height: 100px;
}
.imagesside img:nth-child(even) {
  margin-right: auto;
width: 200px;
height: 200px;
}
[dir="ltr"] .imagesside img:nth-child(2) {
    margin-right: 0 !important;
    margin-left: auto;
}
[dir="ltr"].imagesside img:nth-child(4){
  margin-right: 0 !important;
    margin-left: auto;
}
[dir="ltr"].imagesside img:nth-child(even) {
  margin-right: 0 !important;
    margin-left: auto;
}
.imagesside img:last-child{
    margin-right: 55px;
    margin-top: -85px;
    width: 240px;
    height: 240px;
}
@media (max-width: 1399px) { 
    .imagesside img:last-child{
    margin-right: 0;
   
}
    
}
@media (max-width: 1199px) {

}
@media (max-width: 960px) {
.imagesside{
    width: 60%;
    margin: auto;
}

}
@media (max-width: 767px) {
    .imagesside{
    width: 75%;
}
}
@media (max-width: 575px) {
    .imagesside img:first-child{
width: 90px;
height: 90px;
}
.imagesside img:nth-child(2){
width: 140px;
height: 140px;
}
.imagesside img:last-child{
    margin-top: -50px;
    width: 180px;
    height: 180px;
}
    .imagesside{
    width: 100%;
}
}
</style>
