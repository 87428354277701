<template>
    <div>
        <About/>
    </div>
</template>
<script>
import About from '@/components/About/About.vue'
export default {
    components:{
        About,
    }
}
</script>
<style lang="">
    
</style>